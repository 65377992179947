import React from 'react'
import OtherGrid from '../components/gridBlocks/otherGrid'

export default function Projects() {
  return (
    <>
      <OtherGrid />
    </>
  )
}
